<template>
   <div class="muro-add">
      <!-- Titulo sección + pasos -->
      <div class="muro-add__top">
         <SectionTitle
            :title="title_component.title"
            :subtitle="title_component.subtitle"
         />
         <div class="muro-add__steps">
            <div class="muro-add__steps-container">
               <div
                  @click="handleClickStep('text')"
                  class="step"
                  :class="{
                     active: step == 'text',
                  }"
               >
                  1
               </div>
               <div
                  @click="handleClickStep('upload')"
                  class="step"
                  :class="{
                     active: step == 'upload',
                  }"
               >
                  2
               </div>
               <div
                  @click="handleClickStep('preview')"
                  class="step"
                  :class="{
                     active: step == 'preview',
                  }"
               >
                  3
               </div>
            </div>
         </div>
      </div>
      <!-- Pasos, titulos y botones -->
      <div class="muro-add-container">
         <!-- Titulo de cada paso -->
         <div class="steps-title">
            <h3>
               {{ step_title }}
            </h3>
         </div>
         <div class="muro-add__steps">
            <!-- Paso 1: Escribir post -->
            <div class="text" v-if="step == 'text'">
               <div class="text-container">
                  <textarea
                     v-model="post_text"
                     @keypress="handleTextarea"
                     maxlength="1200"
                  />
               </div>
               <div class="text-counter">{{ post_text.length }} /1200</div>
            </div>
            <!-- Paso 2: Subir imagen  -->
            <div class="upload" v-if="step == 'upload'">
               <UploadImg
                  @sendImg="handleUploadImg"
                  v-if="post_img.base64 == ''"
               />
               <div class="upload__img-preview" v-if="post_img.base64 !== ''">
                  <div class="delete-preview" @click="deletePreview()">
                     <button>
                        <font-awesome-icon icon="times"></font-awesome-icon>
                     </button>
                  </div>
                  <div class="img-preview-container">
                     <img :src="post_img.base64" alt="" />
                  </div>
               </div>
            </div>
            <!-- Paso 3: Vista previa post -->
            <div class="preview" v-if="step == 'preview'">
               <div class="preview-container">
                  <div class="preview-container-top">
                     <div class="user-img">
                        <div class="user-img-container">
                           <img :src="handleUserImg()" alt="" />
                        </div>
                     </div>
                     <div class="user-text">
                        <h4>{{ handleUserName() }}</h4>
                        <p ref="postText">
                           {{ handlePostText() }}
                        </p>
                        <b
                           class="ver-mas"
                           @click="handleTextBtn()"
                           v-if="post_text.length > 150"
                        >
                           {{ text_btn }}
                        </b>
                     </div>
                  </div>
                  <div
                     class="preview-container-bottom"
                     v-if="post_img.base64 !== ''"
                  >
                     <img :src="post_img.base64" alt="" />
                  </div>
               </div>
            </div>
         </div>
         <!-- Botones Siguiente/Publicar -->
         <div class="steps-buttons">
            <button
               class="btn next"
               @click="handleNextStep()"
               v-if="display_button == 'next'"
            >
               Siguente
            </button>
            <button
               class="btn publish"
               @click="publicarPost()"
               v-if="display_button == 'publish'"
            >
               Publicar
            </button>
         </div>
      </div>
      <!--  Modal que muestra el estatus de la accion (exito o error) -->
      <Status
         v-if="open_modal_status"
         :msg="modal_status_msg"
         :status="modal_status"
         @close="closeStatus"
      />
   </div>
</template>

<script>
import SectionTitle from "../Section/SectionTitle.vue";
import UploadImg from "../UploadImg.vue";
import Status from "../Modales/Status.vue";
import gql from "graphql-tag";

const GET_MUROS = gql`
   query muros($id_empresa: String!) {
      muros(id_empresa: $id_empresa) {
         id_muro_ideas
         texto
         usuario {
            id_usuario
            primer_nombre
            apellido_paterno
            imagen_perfil
         }
      }
   }
`;

// const CREAR_MURO = gql`
//    mutation crearMuro(
//       $id_usuario: String!
//       $imagen_muro: String!
//       $formato: String!
//       $id_empresa_fk: String!
//       $texto: String!
//    ) {
//       crearMuro(
//          id_usuario: $id_usuario
//          imagen_muro: $imagen_muro
//          formato: $formato
//          id_empresa_fk: $id_empresa_fk
//          texto: $texto
//       ) {
//          id_muro_ideas
//       }
//    }
// `;

// const EDITAR_MURO = gql`
//    mutation editarMuro(
//       $id_usuario: String!
//       $imagen_muro: String!
//       $formato: String!
//       $id_empresa_fk: String!
//       $texto: String!
//       id_muro: String!
//    ) {
//       editarMuro(
//          id_usuario: $id_usuario
//          imagen_muro: $imagen_muro
//          formato: $formato
//          id_empresa_fk: $id_empresa_fk
//          texto: $texto
//          id_muro: $id_muro
//       ) {
//          id_muro_ideas
//       }
//    }
// `;

export default {
   components: { SectionTitle, UploadImg, Status },
   data() {
      return {
         title_component: {
            title: "Posts",
            subtitle:
               "Completa los siguientes pasos para subir un post al muro",
         },
         step_title: "Escribe algo",
         step: "text",
         post_text: "",
         post_img: {
            width: "",
            height: "",
            base64: "",
            src: "",
            format: "",
         },
         next_step_button: false,
         user_default_img: require("@/assets/img/user_default_img.svg"),
         text_btn: "Ver más",
         display_button: "next",
         open_modal_status: false,
         modal_status_msg: "",
         modal_status: false,
         edit_post: false,
         post_to_edit: null,
      };
   },
   mounted() {
      if (this.$route.params.id != undefined && this.$route.params.id != null) {
         this.edit_post = true;
         this.getPost(this.$route.params.id);
      }
   },
   methods: {
      // Funcion para obtener el post del muro para editar
      getPost(idPost) {
         this.$apollo
            .query({
               query: GET_MUROS,
               variables: { id_empresa: "1" },
            })
            .then((res) => {
               var resp = res.data.muros;
               for (let i = 0; i < resp.length; i++) {
                  if (resp[i].id_muro_ideas == idPost) {
                     console.log("post", resp[i]);
                     this.post_to_edit = resp[i];
                     this.post_text = resp[i].texto;
                  }
               }
            })
            .catch((err) => {
               console.log("err", err);
            });
      },
      // Funcion que permite avanzar al siguiente paso al clickear el boton 'siguiente'
      handleNextStep() {
         if (this.step == "text" && this.post_text.length > 0) {
            this.step = "upload";
            this.step_title = "Agregar imagen (opcional)";
         } else if (this.step == "upload") {
            this.step = "preview";
            this.step_title = "Vista previa";
            this.display_button = "publish";
         }
      },
      // Funcion para navegar entre pasos
      handleClickStep(step) {
         if (this.step == step) {
            return;
         } else {
            if (step == "text") {
               this.step = "text";
               this.step_title = "Escribe algo";
               this.display_button = "next";
            } else if (step == "upload" && this.post_text.length > 0) {
               this.step = "upload";
               this.display_button = "next";
               this.step_title = "Agregar imagen (opcional)";
            } else if (step == "preview" && this.post_text.length > 0) {
               this.step = "preview";
               this.step_title = "Vista previa";
               this.display_button = "publish";
            }
         }
      },
      // Funcion que recibe imagen de componente UploadImg
      handleUploadImg(img) {
         this.post_img = {
            width: img.width,
            height: img.height,
            base64: img.base64,
            src: img.src,
            format: img.format,
         };
      },
      // Funcion que se encarga de cortar y mostrar texto cuando es muy largo (> 150 caracteres)
      handlePostText() {
         if (this.post_text.length > 150) {
            var text = this.post_text.slice(0, 150);
            return text;
         } else {
            return this.post_text;
         }
      },
      // Funcion para eliminar vista previa de imagen subida
      deletePreview() {
         this.post_img = {
            width: "",
            height: "",
            base64: "",
            src: "",
            format: "",
         };
      },
      // Funcion que muestra boton 'Ver más' cuando el texto tiene mas de 150 caracteres
      handleTextarea() {
         if (this.post_text.length > 0) {
            this.next_step_button = true;
         }
      },
      // Funcion que se encarga del boton 'ver mas'
      handleTextBtn() {
         if (this.text_btn == "Ver más") {
            this.$refs.postText.innerHTML = this.post_text;
            this.text_btn = "Ver menos";
         } else {
            this.text_btn = "Ver más";
            var ver_menos = this.post_text.slice(0, 150);
            this.$refs.postText.innerHTML = ver_menos;
         }
      },
      // Funcion para cerrar modal que muestra el estatus de la accion (exito o error)
      closeStatus() {
         this.open_modal_status = false;

         this.step_title = "Escribe algo";
         this.post_text = "";
         this.post_img = {
            width: "",
            height: "",
            base64: "",
            src: "",
            format: "",
         };
         this.handleClickStep("text");
      },
      // Funcion para publicar el post
      publicarPost() {
         this.modal_status_msg = "Post publicado con éxito";
         this.open_modal_status = true;
         this.modal_status = true;
      },
      // Funcion que se encarga de la img del usuario
      handleUserImg() {
         if (this.edit_post) {
            var img = this.post_to_edit.usuario[0].imagen_perfil;
            return img;
         } else {
            return this.user_default_img;
         }
      },
      // Funcion que se encarga del nombre del usuario
      handleUserName() {
         if (this.edit_post) {
            var name =
               this.post_to_edit.usuario[0].primer_nombre +
               " " +
               this.post_to_edit.usuario[0].apellido_paterno;
            return name;
         } else {
            return "Nombre Usuario";
         }
      },
   },
};
</script>